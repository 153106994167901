<template>
    <v-container>
       <v-row>
          <v-col cols="12">
               <v-card :loading="loading" tile>
                    <GmapMap
                         refs="map"
                         :center="center"
                         :zoom="zoom"
                         style="height: 60vh"
                         class="fill-height"
                         width="100%"
                         :options="mapOption"
                         >
                         
                         <template  v-for="(store) in stores">
                         <GmapMarker
                               :key="store.store_code"
                               :position="{lat:parseFloat(store.latitude), lng:parseFloat(store.longitude)}"
                               @click="viewthisStore(store)"
                               :icon="icon"
                         >
                         </GmapMarker>
                         </template>
                         <gmap-info-window
                              :options="{
                                   maxWidth: 300,
                                   pixelOffset: { width: 0, height: -15 }
                              }"
                              :position="infoWindow.position"
                              :opened="infoWindow.open"
                              @closeclick="infoWindow.open=false">
                              <div v-if="infoWindow.template">
                                   <h3 class="my-2">{{infoWindow.template.name}}</h3>
                                   <p>{{infoWindow.template.address}}</p>
                                   <v-btn outlined small block target="_blank" :href="infoWindow.template.maps_url" color="info">
                                        <v-icon class="mx-2">mdi-map</v-icon>
                                        {{$t('commons.actions.gotostore')}}
                                   </v-btn>
                              </div>
                         </gmap-info-window>
                    </GmapMap>
               </v-card>
          </v-col>
          <v-col cols="12" >
               <v-card :loading="loading" class="fill-height" tile>
                    <v-card-title>
                         {{$t('router.stores.title')}}
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text  >
                        <v-text-field
                              @change="geocoLocale"
                              prepend-icon="mdi-map-search"
                              @click:prepend="geocoLocale"
                              name="address"
                              :label="$t('attributes.address.label')"
                              v-model="center.title"
                         ></v-text-field>
                       <small> ( {{center.lat}},{{center.lng}})</small>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text  >
                         <div style="overflow:scroll; height: 100%;">
                         <v-list-item-group
                              v-model="selectedItem"
                              color="primary"
                         >
                         <v-list-item
                              v-for="(store) in stores"
                              :key="store.store_code"
                              @click="viewthisStore(store)"
                         >
                              <v-list-item-icon>
                              <v-icon >mdi-store</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                   <h3>{{ store.name}}</h3>
                                   <small>{{calDis(store.distance)}}</small>
                              </v-list-item-content>
                         </v-list-item>
                         </v-list-item-group>
                         </div>
                    </v-card-text>
               </v-card>
          </v-col>
     </v-row>
    </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios"
import mapStyle from "@/plugins/mapStyle.json"
export default {
     computed: {
           ...mapGetters({
                stores: 'stores/stores',
                loading: 'stores/loading',
          }),
     },
     data: () => ({
          message: null,
          errors: {},
          selectedItem: null,
          center:{
               title: "Milano",
               lat: 45.4643,
               lng: 9.1895,
          },
          zoom: 9,
          icon: {
               url: require('@/assets/map_pin.png'),
               scaledSize: {width: 22, height: 32},
          },
          infoWindow: {
               position: {lat: 0, lng: 0},
               open: false,
               template: ''
          },
          mapOption:{
               zoomControl: true,
               draggable: true,
               mapTypeControl: false,
               moveCamera: false,
               scaleControl: false,
               streetViewControl: false,
               rotateControl: false,
               fullscreenControl: false,
               disableDefaultUI: true,
               styles:mapStyle
          }
     }),
     beforeMount(){
          this.getIpLocation();
     },
     mounted(){
          if(this.stores.length <1){
               this.getStores()
          }
     },
     methods:{
          viewthisStore(store){
               window.scrollTo({ top: 0, behavior: 'smooth' });
               this.zoom = 16
               this.center = {lat:parseFloat(store.latitude), lng:parseFloat(store.longitude)}
               this.openInfoWindowTemplate(store)
               if(this.selectedItem !=store){
                    this.selectedItem = store
                    console.log('selectedItem',this.selectedItem)

               }
          },
          async getStores(){
               var geo = {}
               if(this.center.lat){
                    geo.latitude = this.center.lat
                    geo.longitude = this.center.lng
                    localStorage.setItem('geoLat',this.center.lat)
                    localStorage.setItem('geoLon',this.center.lng)
               }
               this.$store.dispatch('stores/getStores',geo);
          },
          calDis(n){
               if(n<1000){
                    return n+" m"
               }else{
                    return parseFloat(n/1000).toFixed(2)+" Km"
               }
          },
          openInfoWindowTemplate(store) {
            this.infoWindow.position = { lat: parseFloat(store.latitude), lng: parseFloat(store.longitude) }
            this.infoWindow.template = store
            this.infoWindow.open = true
          },
          getIpLocation(){

               if(localStorage.getItem('geopos')){
                    console.log("localStorage.getItem('geopos')",localStorage.getItem('geopos'))
                    var geo =  JSON.parse(localStorage.getItem('geopos'));
                    this.center ={
                         title: geo.title,
                         lat: parseFloat(geo.lat),
                         lng: parseFloat(geo.lng),
                    }
               }else{
                    var self = this
                    axios.get("http://ipinfo.io").then(function (response) {
                         console.log("http://ipinfo.io", response);
                         console.log('response.data.loc',response.data.loc)
                         var geo = response.data.loc.split(',')
                         self.center.lat = parseFloat(geo[0])
                         self.center.lng = parseFloat(geo[1])
                         localStorage.setItem('geopos',JSON.stringify(self.center))
                         
                    })
               }
          },
          async geocoLocale(){
               var url = 'https://maps.googleapis.com/maps/api/geocode/json?address='+this.center.title+'&output=json&key='+ process.env.VUE_APP_MAPS_GEO_API_KEY;
               console.log(url);
               var  self = this
               axios.get(url)  
                .then(response => {
                     var result = response.data.results[0]
                     if(response.data.results){
                         console.log(result);
                         console.log(result.geometry.location.lat,result.geometry.location.lng)
                         self.center.lat = parseFloat(result.geometry.location.lat)
                         self.center.lng = parseFloat(result.geometry.location.lng)
                         localStorage.setItem('geopos',JSON.stringify(self.center))
                         self.getStores()
                     } 
                })
          },
     }
}
</script>

<style scoped>
.fixedfloat{
     position: fixed;
     min-width: 340px;
     top: 10%;
     right: 5%;
}
.overflow-y{
       overflow: hidden;
}
</style>